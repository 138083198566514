import "./App.css";
import React, { useEffect, useState } from "react";
import Diagonal from "./components/layout/Diagonal";
import Footer from "./components/layout/Footer";
import LandingSection from "./components/LandingSection";
import ParticlesCanvas from "./components/layout/ParticlesCanvas";
import ProjectSection from "./components/project/ProjectSection";

function App() {
  const [skewAngle, setSkewAngle] = useState("-7");

  useEffect(() => {
    const calculateSkewAngle = () => {
      const windowHeight = window.innerHeight;
      const sideA = windowHeight * 0.2;
      const sideB = window.innerWidth;
      const angleRadians = Math.atan(sideA / sideB);
      const angleDegrees = angleRadians * (180 / Math.PI);

      setSkewAngle(angleDegrees.toFixed(2));
    };

    calculateSkewAngle();
    window.addEventListener("resize", calculateSkewAngle);

    return () => window.removeEventListener("resize", calculateSkewAngle);
  }, []);

  return (
    <div className="App">
      <Diagonal
        upperColor="rgba(4, 45, 98, 0.7)"
        lowerColor="rgba(0, 0, 0, 0)"
        height="20vh"
        text=""
      />
      <LandingSection />
      <ProjectSection skewAngle={skewAngle} />
      <ParticlesCanvas />
      <Footer />
    </div>
  );
}

export default App;
