import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";

const FooterStyles = styled.footer`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  position: relative;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99;
  padding-bottom: 5vh;
`;

const IconLink = styled.a`
  display: flex;
  align-items: center;
  color: white;
  transition: color 0.3s ease-in-out;
  font-size: 1.2rem;
  text-decoration: none;

  &:hover {
    color: #47eaff;
  }

  @media (max-width: 768px) {
    font-size: 0.7rem;
  }
`;

const SocialMediaIcon = styled(FontAwesomeIcon)`
  font-size: 40px;
  transition: color 0.3s;
  cursor: pointer;
  color: #ffffff !important;

  &:hover {
    color: #47eaff !important;
  }
`;

const socialMediaLinks = {
  github: "https://github.com/elarsaks",
  linkedin: "https://www.linkedin.com/in/elarsaks/",
  facebook: "https://www.facebook.com/elar.saks/",
  instagram: "https://www.instagram.com/elarsaks/",
};

const Footer: React.FC = () => {
  return (
    <FooterStyles>
      <IconLink
        href="https://www.saks.digital"
        target="_blank"
        rel="noopener noreferrer"
      >
        © 2024 Elar Saks
      </IconLink>

      <a
        href={socialMediaLinks.github}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SocialMediaIcon icon={faGithub} />
      </a>
      <a
        href={socialMediaLinks.linkedin}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SocialMediaIcon icon={faLinkedin} />
      </a>
    </FooterStyles>
  );
};

export default Footer;
