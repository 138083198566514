import styled from "styled-components";

interface DiagonalStyleProps {
  $height: string;
  $upperColor: string;
  $lowerColor: string;
}
const Diagonal = styled.div<DiagonalStyleProps>`
  position: relative;
  height: ${(props) => props.$height};
  width: 100%;
  background-image: linear-gradient(
      to bottom right,
      transparent 49.8%,
      white 49.8%,
      white 50.4%,
      transparent 50%
    ),
    linear-gradient(
      to bottom right,
      ${(props) => props.$upperColor} 50%,
      ${(props) => props.$lowerColor} 50%
    );
  z-index: 1;
`;

const Heading = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2; // Ensure text is above the background

  h1 {
    color: white;
    font-weight: 500;
    letter-spacing: 0.2em;
    padding: 5px 10px;
    margin: 0; // Adjust as necessary
    border: 5px solid #ffffff;
    border-radius: 10px;
    background-color: rgba(0, 0, 0, 0.9);
  }
`;

interface DiagonalProps {
  upperColor: string;
  lowerColor: string;
  height: string;
  text: string;
}

// Component that combines Diagonal with text
const DiagonalWithText: React.FC<DiagonalProps> = ({
  upperColor,
  lowerColor,
  height,
  text,
}) => {
  return (
    <Diagonal
      $upperColor={upperColor}
      $lowerColor={lowerColor}
      $height={height}
    >
      {text && (
        <Heading>
          <h1>{text}</h1>
        </Heading>
      )}
    </Diagonal>
  );
};

export default DiagonalWithText;
