import styled from "styled-components";

type ProjectWrapperStyleProps = {
  $skewAngle?: string;
};

const ProjectWrapper = styled.div<ProjectWrapperStyleProps>`
  position: relative;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: 20px auto;
  padding: 1rem;
  background-color: rgba(0, 0, 0, 0.8);
  border: 3px solid #ffffff;
  border-radius: 10px;
  color: #ffffff;
  overflow: hidden;
  transition: transform 0.5s ease, background-color 0.5s ease;
  max-width: 1400px;

  @media (max-width: 1024px) {
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    transform: none;
  }
`;

const ProjectText = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  font-size: 1.3rem;

  h3 {
    margin-bottom: 1rem;
  }

  p {
    margin: 0.5rem 0;
  }

  a {
    text-decoration: none;
    color: #0e7bf7;
    font-weight: bold;
    margin: 0 10px 0 0;

    &:hover {
      text-decoration: underline;
    }

    &:last-child {
      margin-right: 0;
    }
  }
`;

const ProjectImage = styled.div`
  border-left: 3px solid #ffffff;
  flex: 1.2;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  width: 100%;

  @media (max-width: 1024px) {
    min-height: 100px;
    border-left: none;
    border-top: 3px solid #ffffff;
  }
`;

const StyledImage = styled.img`
  max-width: 100%;
  max-height: 100%;
  border-radius: 0 10px 10px 0;

  @media (max-width: 1024px) {
    border-radius: 0 0 10px 10px;
  }
`;

type Project = {
  name: string;
  description: string;
  technologies: string;
  demo?: string;
  sourceCode?: string;
  image: string;
};

type ProjectComponentProps = {
  project: Project;
  skewAngle: string;
};

const ProjectComponent: React.FC<ProjectComponentProps> = ({
  project,
  skewAngle,
}) => {
  const navigateTo = () => {
    if (project.demo) {
      window.open(project.demo, "_blank");
    }
  };

  return (
    <ProjectWrapper $skewAngle={skewAngle}>
      <ProjectText>
        <h3>{project.name}</h3>
        <p>{project.description}</p>
        <p>
          <b>Technologies:</b> {project.technologies}
        </p>
        <div>
          {project.demo && (
            <a href={project.demo} target="_blank" rel="noopener noreferrer">
              Live Demo
            </a>
          )}
          {project.sourceCode && (
            <a
              href={project.sourceCode}
              target="_blank"
              rel="noopener noreferrer"
            >
              GitHub
            </a>
          )}
        </div>
      </ProjectText>
      <ProjectImage onClick={navigateTo}>
        <StyledImage src={project.image} alt={`${project.name} logo`} />
      </ProjectImage>
    </ProjectWrapper>
  );
};

export default ProjectComponent;
